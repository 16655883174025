import './src/styles/_base.scss';

const ACTIVE_COOKIE_BANNER_TYPE = process.env.GATSBY_COOKIE_BANNER_TYPE;
const COOKIE_BANNER_TYPE = {
  LEGACY: 'LEGACY',
  PORSCHE: 'PORSCHE',
  COOKIEBOT: 'COOKIEBOT',
};

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const isAnchorLink = routerProps.location.hash.startsWith('#');

  if (isAnchorLink) return true;

  return routerProps.location.pathname !== prevRouterProps?.location?.pathname;
};

function initLegacyGTM() {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GATSBY_GA_TRACKING_ID}');`;

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', `${process.env.GATSBY_GA_TRACKING_ID}`);
  };

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<iframe src=https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GA_TRACKING_ID} height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.head.insertBefore(script, document.head.childNodes[1]);
  document.body.insertBefore(noscript, document.body.childNodes[0]);
}

function loadGTM(event) {
  initGTM();
  initLegacyGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}

function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  }
  window.isGTMLoaded = true;

  gtag('consent', 'update', {
    ad_user_data:
      document?.cookie?.indexOf('marketingCookies') > -1 ? 'granted' : 'denied',
    ad_personalization:
      document?.cookie?.indexOf('marketingCookies') > -1 ? 'granted' : 'denied',
    ad_storage:
      document?.cookie?.indexOf('marketingCookies') > -1 ? 'granted' : 'denied',
    analytics_storage: 'granted',
    google_analytics: 'granted',
  });
}

export const onClientEntry = () => {
  switch (ACTIVE_COOKIE_BANNER_TYPE) {
    case COOKIE_BANNER_TYPE.COOKIEBOT:
      initCookiebot();
      break;
    case COOKIE_BANNER_TYPE.PORSCHE:
      initPorsche();
      break;
    case COOKIE_BANNER_TYPE.LEGACY:
      initLegacy();
      break;
  }
};

export const onRouteUpdate = ({ location }) => {
  const locale = location.pathname.split('/')[1];

  if (window.fetchLocale === locale) return;

  if (window.fetchLocale) {
    window.fetch = window.oldFetch;
  }

  const oldFetch = window.fetch;
  window.oldFetch = oldFetch;
  window.fetchLocale = locale;
  window.fetch = (path, config = null) => {
    if (config?.withoutLocale) {
      return oldFetch(path, config);
    }
    const pathWithLocale = `${path}${
      path.includes('?') ? '&' : '?'
    }language=${locale}`;
    return oldFetch(pathWithLocale, config);
  };
};

const initCookiebot = () => {
  // CookiebotOnConsentReady event is triggered when the user’s consent state is ready, either from being submitted or loaded from an existing cookie.
  window.addEventListener('CookiebotOnConsentReady', function () {
    if (window.CookieBot?.consent.statistics) {
      initGTM();
      initLegacyGTM();
    }
  });
};

const initLegacy = () => {
  if (document?.cookie?.indexOf('statisticalCookies') > -1) {
    document.onreadystatechange = () => {
      if (document.readyState !== 'loading') {
        setTimeout(initGTM, 3500);
      }
    };
    document.addEventListener('scroll', loadGTM);
    document.addEventListener('mousemove', loadGTM);
    document.addEventListener('touchstart', loadGTM);
  } else {
    document.addEventListener('statisticalCookies', loadGTM);
  }
};

const initPorsche = () => {
  document.addEventListener('statisticalCookies', loadGTM);
};
