import { useEffect, useState } from 'react';
import { getAPIRoot } from '../helpers/fetch';
import {
  getJurorYearFilters,
  getOpenedFilters,
  getMappedJurorFilterDates,
} from '../helpers/filters';
import { API_ROUTES, AWARD_IDS, AWARDS } from '../utils/constants';

const withCustomAwards = (awards, type) =>
  [
    ...(type === 'student'
      ? [
          {
            ...awards.find((award) => award.id === AWARD_IDS.IFDSA),
            name: AWARDS.IFDSA,
            hide: true,
            locked: true,
          },
        ]
      : type === 'sip'
      ? [
          {
            ...awards.find((award) => award.id === AWARD_IDS.IFSIP),
            name: AWARDS.IFSIP,
            hide: true,
            locked: true,
          },
        ]
      : [
          {
            ...awards.find((award) => award.id === AWARD_IDS.IFDA),
            hide: true,
            locked: true,
          },
        ]),
  ].reverse();

// adapt current filters to what backend expects
const prepareFiltersForAPI = (filters, currentYear = 2025) => {
  // Get results from all years, if no specific time filter is applied
  // Without that, we don't get any results on the /search page
  const timeFilters = filters?.time ?? [2];
  const filtersForAPI = {
    awards: filters?.awards || [],
    countries: filters?.country || [],
    years: Array.from(
      new Set(
        timeFilters
          .map((year) => getMappedJurorFilterDates(currentYear)[year])
          .flat()
      )
    ),
    find: filters?.find || '',
  };

  return JSON.stringify(filtersForAPI);
};

export const useJurorFilters = (filters, items = [], type = null) => {
  const [totalCount, setTotalCount] = useState(null);
  const [visibleFilters, setVisibleFilters] = useState(null);
  const [page, setPage] = useState(0);
  const [tempFilters, setTempFilters] = useState(null); // used when deferred
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noMoreResults, setNoMoreResults] = useState(true);

  // get filters from backend when component loads
  useEffect(() => {
    getAPIFilters();
  }, []);

  // Reset Page on filter change
  useEffect(() => {
    setPage(0);
  }, [filters]);

  // update results on page change and filter change once we have calculated what filters should be visible
  useEffect(() => {
    if (visibleFilters && filters) {
      updateResults();
    }
  }, [page, visibleFilters, filters]);

  const increasePage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getFiltersThatShouldApply = () => {
    // get only filters that are visible
    return Object.fromEntries(
      Object.entries(filters).map(([key, appliedFilter]) => {
        if (Array.isArray(appliedFilter)) {
          return [
            key,
            appliedFilter.filter((appliedFilterItem) =>
              visibleFilters[key].find(
                (visibleFilter) => visibleFilter.id === appliedFilterItem
              )
            ),
          ];
        } else {
          return [key, appliedFilter];
        }
      })
    );
  };

  const getFilteredResultsJsonResponse = async () => {
    let apiRoot = await getAPIRoot();

    const filtersThatShouldApply = getFiltersThatShouldApply();

    const payload = prepareFiltersForAPI(
      filtersThatShouldApply,
      visibleFilters.awards[0].year
    );

    const url = `${apiRoot}${API_ROUTES.JURORS}/${page}/${
      filters.sort || 'desc'
    }`;
    const options = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    };

    const response = await fetch(url, options);
    return response.json();
  };

  // get filters from backend, rename them and set state
  const getAPIFilters = async () => {
    let apiRoot = await getAPIRoot();
    const url = apiRoot + API_ROUTES.JURORS_FILTERS;

    const response = await fetch(url);
    const _apiFilters = await response.json();

    let year;

    if (type === 'sip') {
      year = _apiFilters['awards'].find(
        (el) => el.name === 'SOCIAL PROJECTS'
      ).year;
    } else if (type === 'student') {
      year = _apiFilters['awards'].find(
        (el) => el.name === 'iF DESIGN TALENT AWARD'
      ).year;
    } else {
      year = _apiFilters['awards'].find(
        (el) => el.name === 'iF DESIGN AWARD'
      ).year;
    }

    const jurorYearFilters = getJurorYearFilters(year, type === 'sip');

    setVisibleFilters({
      sort: [
        {
          id: 'desc',
          name: 'Newest',
        },
        {
          id: 'asc',
          name: 'Oldest',
        },
      ],
      awards: withCustomAwards(_apiFilters['awards'], type),
      time: jurorYearFilters,
      country: _apiFilters['countries'],
    });
  };

  const updateResults = async () => {
    setLoading(true);

    const json = await getFilteredResultsJsonResponse();

    setTotalCount(json.count);

    if (!json.items.length) {
      setNoMoreResults(true);
    }

    if (page > 0) {
      const _results = results.concat(json.items);
      if (_results.length === json.count) {
        setNoMoreResults(true);
      }
      setResults(_results);
    } else {
      setResults(json.items);
      setNoMoreResults(json.items.length === json.count);
    }

    setLoading(false);
  };

  return {
    filterOrderByKey: ['sort', 'awards', 'time', 'country'],
    openedFilters: getOpenedFilters(filters, ['sort', 'awards', 'time']),
    visibleFilters,
    radioFiltersKeys: ['sort', 'time'],
    increasePage,
    results,
    loading,
    noMoreResults,
    totalCount,
  };
};
