export const fragments = {
  Container: {
    name: 'container',
    value: `fragment container on Container {
      description
      outerSpacing
      innerSpacing
      horizontalColsGap: horizontalColumnsGap
      beigeBackground
      contentItems: contentCollection(limit: 5) {
        items {
          ...textVisual
        }
      }
      textContent {
        raw: json
      }
      cta {
        ...link
      }
      __typename
      anchorLabel
    }`,
  },
  ContentMediaWrapper: {
    name: 'mediaWrapper',
    value: `fragment mediaWrapper on ContentMediaWrapper {
      use2ColumnLayout
      mediaItems: mediaItemsCollection(limit: 2) {
        items {
          ...video
          ...image
        }
      }
      __typename
      anchorLabel
    }`,
  },
  Video: {
    name: 'video',
    value: `fragment video on Video {
  localVideo {
    url
  }
  youtubeUrl
  poster {
    ...image
  }
  videoCaption {
    raw: json
  }
  badge
  badgeYear
  __typename
}`,
  },
  Document: {
    name: 'document',
    value: `fragment document on Document {
  document {
    title
    contentType
    size
    url
  }
  language
  __typename
}`,
  },
  Link: {
    name: 'link',
    value: `fragment link on Link {
  externalLink
  text: label
  linkToPage {
    ... on PressRelease {
      slug
      __typename
    }
    ... on Article {
      slug
      __typename
    }
    ... on Page {
      slug
      __typename
    }
  }
  linkToDynamicPage
  dynamicDetailPageId
  dynamicDetailPageName
  queryParameter
  downloadDocument {
    ...document
  }
  icon
  anchor
  type
  transparent: isTransparent
  __typename
}`,
  },
  Image: {
    name: 'image',
    value: `fragment image on Image {
  image {
    title
    description
    url
    contentType
  }
  imageCaption {
    raw: json
  }
  badge
  badgeYear
  focusPoint
  format
  __typename
}`,
  },
  Content2ColumnImageTextWrapper: {
    name: 'twoColumnImageTextWrapper',
    value: `fragment twoColumnImageTextWrapper on Content2ColumnImageTextWrapper {
  content2ColumnImageTextItems: content2ColumnImageTextItemsCollection(limit: 5) {
    items {
      headline
      subline
      text {
        raw: json
      }
      link {
        ...link
      }
      image {
        ...image
      }
    }
  }
  __typename
  anchorLabel
}`,
  },
  ContentForm: {
    name: 'form',
    value: `fragment form on ContentForm {
  inputLabel: label
  inputPlaceholder
  btnLabel
  type
  action
  __typename
}`,
  },
  ContentAccordeonItem: {
    name: 'accordeonItem',
    value: `fragment accordeonItem on ContentAccordeonItem {
  headline
  content: contentCollection(limit: 2) {
    items {
      ... on ContentTextVisualWrapper {
        headline {
          raw: json
        }
        beigeBackground
        spacing
        itemsCollection(limit: 2) {
          items {
            ... on ContentTextVisualItem {
              ...textVisualItem
            }
          }
        }
        articleImageSizes
        __typename
        description
        mobileLayout
        anchorLabel
      }
      ...quote
      ...download
      ...dynamicTeaser
    }
  }
  __typename
}
fragment accordeonItemInsideTextVisual on ContentAccordeonItem {
  headline
  content: contentCollection(limit: 2) {
    items {
      ... on ContentTextVisualWrapper {
        headline {
          raw: json
        }
        beigeBackground
        spacing
        __typename
        description
        mobileLayout
        anchorLabel
      }
    }
  }
  __typename
}`,
  },
  ContentAccordeonWrapper: {
    name: 'accordeonWrapper',
    value: `fragment accordeonWrapper on ContentAccordeonWrapper {
  headline {
    raw: json
  }
  subline {
    raw: json
  }
  content: contentCollection(limit: 15) {
    items {
      ...accordeonItem
    }
  }
  anchorLabel
  cta {
    ...link
  }
  beigeBackground
  __typename
  description
}
fragment accordeonWrapperInsideTextVisual on ContentAccordeonWrapper {
  headline {
    raw: json
  }
  subline {
    raw: json
  }
  content: contentCollection(limit: 2) {
    items {
      ...accordeonItemInsideTextVisual
    }
  }
  anchorLabel
  cta {
    ...link
  }
  beigeBackground
  __typename
  description
}
`,
  },
  ContentSeperator: {
    name: 'separator',
    value: `fragment separator on ContentSeperator {
  backgroundColor
  backgroundImage {
    ...image
  }
  title: headline
  textAboveHeadline
  ctas: linksCollection(limit: 3) {
    items {
      ...link
    }
  }
  form {
    ...form
  }
  __typename
  description
  image {
    ...image
  }
  anchorLabel
}`,
  },
  ContentSeperatorRedesign: {
    name: 'separatorRedesign',
    value: `fragment separatorRedesign on ContentSeperatorRedesign {
  backgroundColor
  backgroundImage {
    ...image
  }
  title: headline
  textAboveHeadline
  ctas: linksCollection(limit: 3) {
    items {
      ...link
    }
  }
  form {
    ...form
  }
  __typename
  description
  media {
    ...image
    ...video
  }
  mobileImage {
    ...image
  }
  body {
    raw: json
  }
  fullWidth
  rightAlignedMedia
  containedMedia
  anchorLabel
}`,
  },
  ContentHeroStageTeaser: {
    name: 'heroTeaser',
    value: `fragment heroTeaser on ContentHeroStageTeaser {
  headline
  text
  cta: link {
    ...link
  }
  ctas: ctasCollection(limit: 2) {
    items {
      ...link
    }
  }
  position
  isRed
  bigHeadline
  isEditorial
}`,
  },
  ContentHeroStage: {
    name: 'hero',
    value: `fragment hero on ContentHeroStage {
  background {
    ...image
    ...video
  }
  backgroundImageLink {
    ...link
  }
  heroTeaser: teaser {
    ...heroTeaser
  }
  anchorLabel
  __typename
}`,
  },
  ContentTextQuote: {
    name: 'textQuote',
    value: `fragment textQuote on ContentTextQuote {
  quoteText: text
  __typename
}`,
  },
  ContentTextTeaser: {
    name: 'textTeaser',
    value: `fragment textTeaser on ContentTextTeaser {
  headline {
    raw: json
  }
  text {
    raw: json
  }
  link {
    ...link
  }
  __typename
}`,
  },
  ContentTextRelatedPerson: {
    name: 'textRelatedPerson',
    value: `fragment textRelatedPerson on ContentTextRelatedPerson {
  name
  image {
    ...image
  }
  text {
    raw: json
  }
  __typename
}`,
  },
  ContentText: {
    name: 'text',
    value: `fragment text on ContentText {
  text {
    raw: json
  }
  teaser: contentTeaser {
    ...textQuote
    ...textTeaser
    ...textRelatedPerson
  }
  asideTeaserText {
    raw: json
  }
  type
  asideContentDownload {
    ...download
  }
  ctas: ctasCollection(limit: 2) {
    items {
      ...link
    }
  }
  displayTeaserOnRight
  beigeBackground
  __typename
  description
  anchorLabel
}`,
  },
  ContentDownload: {
    name: 'download',
    value: `fragment download on ContentDownload {
  headline {
    raw: json
  }
  image {
    ...image
  }
  documents: documentsCollection(limit: 5) {
    items {
      ...document
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentRelatedContentWrapper: {
    name: 'relatedContent',
    value: `fragment relatedContent on ContentRelatedContentWrapper {
  headline {
    raw: json
  }
  content: contentCollection(limit: 6) {
    items {
      image {
        ...image
      }
      link {
        ...link
      }
      title
      text {
        raw: json
      }
      info
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentQuote: {
    name: 'quote',
    value: `fragment quote on ContentQuote {
  name
  text {
    raw: json
  }
  image {
    ...image
  }
  useBlueBackground
  __typename
  description
  anchorLabel
}`,
  },
  ContentEditorialTeaser: {
    name: 'editorialTeaser',
    value: `fragment editorialTeaser on ContentEditorialTeaser {
  headline {
    raw: json
  }
  type
  link {
    ...link
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentTeaser: {
    name: 'teaserItem',
    value: `fragment teaserItem on ContentTeaser {
  headline
  text {
    raw: json
  }
  image {
    ...image
  }
  video {
    ...video
  }
  link {
    ...link
  }
  hideArrowAndText
  badgeText
  badgeIcon
  badgeYear
  __typename
}`,
  },
  ContentTeaserWrapper: {
    name: 'teaserWrapper',
    value: `fragment teaserWrapper on ContentTeaserWrapper {
  headline {
    raw: json
  }
  text {
    raw: json
  }
  link {
    ...link
  }
  displayTextOnRightSide
  type
  beigeBackground
  teaserItems: teaserCollection(limit: 6) {
    items {
      ...teaserItem
    }
  }
  spacing
  __typename
  description
  mobileLayout
  anchorLabel
}`,
  },
  HighlightTeaserWrapper: {
    name: 'highlightTeaserWrapper',
    value: `fragment highlightTeaserWrapper on HighlightTeaserWrapper {
  mediaItems: mediaCollection(limit: 3) {
    items {
      ...image
      ...video
    }
  }
  tagText
  tagColor
  headline {
    raw: json
  }
  text {
    raw: json
  }
  link {
    ...link
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentQuickLinkIconTeaserItem: {
    name: 'quickLinkIconTeaserItem',
    value: `
fragment quickLinkIconTeaserItem on ContentQuickLinkIconTeaserItem {
  icon
  title
  text
  link {
    ...link
  }
  __typename
}`,
  },
  ContentQuickLinkIconTeaser: {
    name: 'quickLinkIconTeaser',
    value: `fragment quickLinkIconTeaser on ContentQuickLinkIconTeaser {
  headline {
    raw: json
  }
  itemsCollection(limit: 4) {
    items {
      ...quickLinkIconTeaserItem
    }
  }
  useRedBackground
  __typename
  description
  anchorLabel
  cta {
    ...link
  }
}`,
  },
  ContentParticipationTeaser: {
    name: 'participationTeaser',
    value: `fragment participationTeaser on ContentParticipationTeaser {
  headline {
    raw: json
  }
  media {
    ...video
    ...image
  }
  awardBadge
  awardYear
  link {
    ...link
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentContactItem: {
    name: 'contactItem',
    value: `fragment contactItem on ContentContactItem {
  icon
  contactInformation {
    raw: json
  }
  headquarter
  locations
  founded
  employees
  displayAsTable
  __typename
}`,
  },
  ContentContact: {
    name: 'contact',
    value: `fragment contact on ContentContact {
  headline {
    raw: json
  }
  socialLinks: socialLinksCollection(limit: 10) {
    items {
      ...link
    }
  }
  itemsCollection(limit: 10) {
    items {
      ...contactItem
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentDynamicTeaser: {
    name: 'dynamicTeaser',
    value: `fragment dynamicTeaser on ContentDynamicTeaser {
  headline {
    raw: json
  }
  type
  dataIds
  amountOfRandomTeaser
  limit
  queryParameters
  link {
    ...link
  }
  firstItemHeadline {
    raw: json
  }
  firstItemText
  firstItemLink {
    ...link
  }
  beigeBackground
  totalAmountLabel
  displayTotalAmount
  collectionImages: collectionImagesCollection(limit: 5) {
    items {
      ...image
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentGallery: {
    name: 'gallery',
    value: `fragment gallery on ContentGallery {
  headline {
    raw: json
  }
  images: imagesCollection(limit: 10) {
    items {
      ...image
      ...video
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentSearch: {
    name: 'search',
    value: `fragment search on ContentSearch {
  type
  placeholderText
  chips: chipsCollection(limit: 10) {
    items {
      label
      tag
      behaviour
      queryParameters
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentTextVisualItem: {
    name: 'textVisualItem',
    value: `fragment textVisualItem on ContentTextVisualItem {
  textVisualSubline: subline
  media {
    ...image
    ...video
  }
  articleType
  textVisualText: text {
    raw: json
  }
  link {
    ...link
  }
  backgroundColor
  __typename
}`,
  },
  ContentTextVisualWrapper: {
    name: 'textVisual',
    value: `fragment textVisual on ContentTextVisualWrapper {
  headline {
    raw: json
  }
  sublineAbove {
    raw: json
  }
  beigeBackground
  spacing
  itemsCollection(limit: 4) {
    items {
      ... on ContentTextVisualItem {
        ...textVisualItem
      }
      ... on ContentAccordeonWrapper {
        ...accordeonWrapperInsideTextVisual
      }
    }
  }
  articleImageSizes
  __typename
  description
  mobileLayout
  anchorLabel
}`,
  },
  ContentPressReleaseTeaser: {
    name: 'pressReleaseTeaser',
    value: `fragment pressReleaseTeaser on ContentPressReleaseTeaser {
  type
  headline {
    raw: json
  }
  link {
    ...link
  }
  tabLabel1
  tab1Headline {
    raw: json
  }
  tab1HeadlineMorePressRelease {
    raw: json
  }
  tabLabel2
  tab2Headline {
    raw: json
  }
  tab2HeadlineDownloadImages {
    raw: json
  }
  tab2HeadlineDownloadNewsletterDocuments {
    raw: json
  }
  tabLabel3
  tab3Headline {
    raw: json
  }
  tab3HeadlineDownloadImagePackages {
    raw: json
  }
  topStories: topStoriesCollection(limit: 3) {
    items {
      slug
      headline
      subline
      image {
        ...image
      }
    }
  }
  __typename
  description
  anchorLabel
}`,
  },
  ContentEventTeaser: {
    name: 'eventTeaser',
    value: `fragment eventTeaser on ContentEventTeaser {
  headline {
    raw: json
  }
  text {
    raw: json
  }
  link {
    ...link
  }
  specificIds
  __typename
  description
  fromDate
  toDate
  order
  anchorLabel
}`,
  },
  ContactForm: {
    name: 'contactForm',
    value: `fragment contactForm on ContactForm {
  description
  type
  image {
    ...image
  }
  keyword
  pdfUrl
  __typename
  anchorLabel
}`,
  },
};

export const getPage = (id, locale = 'en', includedTypes = []) => {
  return `
${getFragments([...includedTypes, 'ContentHeroStage']).replace(
  /(\W)\s*/g,
  '$1'
)}
{
  page(id: "${id}", locale: "${locale}", preview: true) {
    title
    slug
    seoDescription
    heroTeaser {
      ...hero
    }
    heroCarouselTeaser {
      ...heroTeaser
    }
    heroCarousel: heroCarouselCollection(limit: 6)  {
      items {
        ...hero
      }
    }
    content: contentCollection(limit: 15) {
      items {
        ${Array.from(new Set(includedTypes))
          .map((type) => `...${fragments[type]?.name}`)
          .join(' ')}
      }
    }
    hasWhiteNavigation
    metaNaviWhite
    footerColor
    external
    hideHeaderAndFooter
    __typename
  }
}
`;
};

export const getPressRelease = (id, locale = 'en') => `
${getFragments([
  'Image',
  'ContentText',
  'ContentDownload',
  'ContentSeperator',
  'ContentTextVisualWrapper',
  'ContentContact',
]).replace(/(\W)\s*/g, '$1')}
query {
  pressRelease(id: "${id}", locale: "${locale}") {
    type
    headline
    seoTitle
    seoDescription
    subline
    date
    slug
    image {
      ...image
    }
    text {
      ...text
    }
    download {
      ...download
    }
    separator {
      ...separator
    }
    textVisual {
      ...textVisual
    }
    contact {
      ...contact
    }
    __typename
  }
}
`;

export const getArticle = (id, locale = 'en', includedTypes = []) => {
  const allIncludedTypes = [
    'Image',
    'ContentDynamicTeaser',
    'ContentRelatedContentWrapper',
    ...includedTypes,
  ];
  return `
${getFragments(allIncludedTypes).replace(/(\W)\s*/g, '$1')}
query {
  article(id: "${id}", locale: "${locale}", preview: true) {
    articleCategories
    headline
    seoTitle
    seoDescription
    subline
    date
    readingTime
    slug
    images: imagesCollection(limit: 3) {
      items {
        ...image
      }
    }
    articleContentElements: articleContentElementsCollection(limit: 25) {
      items {
        ${includedTypes.map((type) => `...${fragments[type].name}`).join(' ')}
      }
    }
    relatedContent {
      ...relatedContent
      ...dynamicTeaser
    }
    backgroundColorInOverview
    __typename
  }
}
`;
};

// get all fragment objects and dependencies from a specified content type
const addFragmentAndDependencies = (type, added = []) => {
  added.push(fragments[type]);
  const unparsedDependencies = fragments[type]?.value.match(/\.\.\.\w+/g);
  const dependencies = new Set(
    (unparsedDependencies || [])
      .filter(Boolean)
      .map((el) => el.replace(/\.\.\./g, ''))
  );
  dependencies.forEach((dependency) => {
    if (!added.find((fragmentObject) => fragmentObject.name === dependency)) {
      addFragmentAndDependencies(
        Object.keys(fragments).find(
          (key) => fragments[key].name === dependency
        ),
        added
      );
    }
  });
  return added;
};

// get all gql fragments for specified content types
export const getFragments = (includedTypes) => {
  const fragmentsAndDependenciesSet = new Set(
    includedTypes
      .map((type) => addFragmentAndDependencies(type))
      .flat()
      .map((el) => el?.name)
  );

  return Array.from(fragmentsAndDependenciesSet)
    .map(
      (fragmentName) =>
        Object.values(fragments).find(
          (fragmentObject) => fragmentObject.name === fragmentName
        )?.value
    )
    .join(' ');
};

export const getPageContentTypes = (id, locale = 'en') => `
{
  page(id: "${id}", locale: "${locale}", preview: true) {
    content: contentCollection(limit: 25) {
      items {
        __typename
      }
    }
  }
}
`;

export const getArticleContentTypes = (id, locale = 'en') => `
{
  article(id: "${id}", locale: "${locale}", preview: true) {
    articleContentElements: articleContentElementsCollection(limit: 25) {
      items {
        __typename
      }
    }
  }
}
`;
